import React from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import SEO from "../../components/SEO";
import PageHeader from "../../components/PageHeader";
import LinkButton from "../../components/LinkButton";

export default () => (
  <>
    <SEO
      description="株式会社SocialDogへのお問い合わせ。"
      title="お問い合わせ"
      meta={[
        {
          name: "robots",
          content: "noindex",
        },
      ]}
    />
    <PageHeader title="Contact" lead="お問い合わせ" />
    <Container>
      <SuccessBody>
        <SuccessHeading>送信完了しました</SuccessHeading>
        <SuccessLead>内容を確認の上、ご連絡いたします。</SuccessLead>
        <LinkButton to="/">ホームへもどる</LinkButton>
      </SuccessBody>
    </Container>
  </>
);

const SuccessBody = styled.div`
  text-align: center;
  margin: 0 0 100px;
`;

const SuccessHeading = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main};
  margin: 0 0 5px;
`;

const SuccessLead = styled.p`
  margin: 0 0 20px;
`;
